import React from 'react';
import { CoordenadaDTO } from "../mapaLeaflet/Coordenada.model";
import Servicios from "../modules/Servicios";
import DescripcionSitio from "../modules/DescripcionSitio";
import SiderA from '../modules/SiderA';
import SliderHome from '../modules/SliderHome';
import ParallaxUno from '../modules/ParallaxUno';

export default function Home() {


    //11.2379455, -74.207022
    const coordenadas: CoordenadaDTO[] = [{ lat: 11.2379455, lng: -74.207022 }];

    return (

        <div className='row'>



                <div className="col-12 col-sm-12" >
                    <SliderHome />
                </div>

            <div className="col-12 col-sm-12" >
                    <SiderA />
                </div>

            <Servicios></Servicios>
            <ParallaxUno></ParallaxUno>

        </div>

    )

}