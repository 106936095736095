import Alturas from './../img/servicios/tsa.png'
import Analisis from './../img/servicios/analisis.png'
import Accidente from './../img/servicios/accidente.png'
import EspaciosConfinados from './../img/servicios/espacioconfinado.png'
import Formacion from './../img/servicios/formacion.png'
import Asesoria from './../img/servicios/asesoria.png'
import Sggst from './../img/servicios/sggst.png'
import Manejo from './../img/servicios/manejodefensivo.png'


export default function Servicios() {
    return (
        <div className='row'>
            <section className="container-fluid mx-auto text-center pt-2">


                <div>
                    <h3 className='p-3 fs-3 border-top border-3 '>
                        Nuestros Servicios
                    </h3>
                </div>


                <div className='row w-100 mx-auto'>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Alturas} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Trabajo seguro en alturas</h5>
                        <p className='px-4 text-center'></p>
                    </div>


                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Analisis} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Análisis de puesto de trabajo</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Accidente} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>AT - Investigación de accidentes de trabajo</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={EspaciosConfinados} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Trabajo seguro en espacios confinados</h5>
                        <p className='px-4 text-center'></p>
                    </div>


                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Formacion} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Formación</h5>
                        <p className='px-4 text-center'></p>
                    </div>


                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Asesoria} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Asesoría legal</h5>
                        <p className='px-4 text-center'></p>
                    </div>


                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Sggst} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Sistema de gestión en seguridad y salud en el trabajo</h5>
                        <p className='px-4 text-center'></p>
                    </div>



                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Manejo} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Manejo defensivo</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                </div>
            </section>

        </div>

    )
}