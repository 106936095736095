import Slider_1 from './../img/sliderhome/slider01.png'
import Slider_2 from './../img/sliderhome/slider02.png'

import Carousel from 'react-bootstrap/Carousel';

export default function SliderHome() {
    return (
        <div className='row'>


            <Carousel interval={3500}>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_1} alt='First slide' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_2} alt='Third slide' />
                    </Carousel.Item>
                </Carousel>
        </div>
    )
}