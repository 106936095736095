
import Servicios from '../modules/Servicios';
import QuienesSomos from '../modules/QuienesSomos';
import ParallaxUno from '../modules/ParallaxUno';
export default function Nosotros() {

    return (
        <div>
            <QuienesSomos />
            <ParallaxUno></ParallaxUno>
        </div>
    )

}