import Profesiones from './../img/profesiones.jpg'



export default function SiderA() {
  return (
    <div className="me-12 d- mt-4 text-center"  >
      <div className='row'>
        <div className='col-12'><hr></hr></div>
        <h3>A & E Ocupacionales</h3>
        <div className='col-12'><hr></hr></div>
      </div>

      <div className='row'>

         <div className="col-12 col-sm-6 boxcenter text-center">
          <p style={{ fontSize: '18px', textAlign: 'justify' }}>
            <strong>En A & E OCUPACIONALES S.A.S</strong> damos respuesta a los retos del sector en materia de seguridad y gestión del riesgo, y prestamos asistencia técnica para garantizar niveles de seguridad compatibles con su actividad, el entorno y el cumplimiento de los requisitos normativos.
          </p>
        </div>

        <div className='col-12 col-sm-6'>
          <p >
            <img className='d-block w-100' src={Profesiones} alt='Mensajero' />
          </p>
        </div>

      </div>






    </div>

  )
}