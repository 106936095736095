
import { Link, NavLink } from 'react-router-dom';
import { Background } from 'react-parallax';

import Logo from './../img/logo.png'

export default function Menu() {

  const claseActiva = "active";

  return (
    <div className=''>


      <div>
        <div className=' d-md-block d-none'>
          
        <div className="row align-items-center text-center  ">
          <div className="col">
            <div className="topbarElements d-inline-flex align-items-center ">
              <div><i className="fas fa-home me-3" /></div>
              <div className="text-start d-md-block d-none">
                <h5 className="p-0 m-0 text-uppercase">Dirección</h5>
                <p className="p-0 m-0 text-secondary">Cra 68C # 77 – 61. Bogotá, colombia</p>
              </div>
            </div>
          </div>
          <div className="col border-start border-end">
            <div className="topbarElements d-inline-flex align-items-center">
              <div><i className="fas fa-envelope me-3"></i></div>
              <div className="text-start d-md-block d-none">
                <h5 className="p-0 m-0 text-uppercase">Email</h5>
                <p className="p-0 m-0 text-secondary">servicios@ayeocupacionales.com</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="topbarElements d-inline-flex align-items-center">
              <div>
                <i className="fas fa-phone me-3" />
              </div>
              <div className="text-start d-md-block d-none">
                <h5 className="p-0 m-0 text-uppercase">Celular</h5>
                <p className="p-0 m-0 text-secondary">+57 3158002914</p>
              </div>
            </div>
          </div>
        </div>

        </div>


        <nav className="navbar navbar-expand-lg navbar-dark  p-0 m-0" style={{ backgroundColor: '#B71719' }}>
          <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src={Logo} alt="DBK Logo" height={72} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className=" collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto ">

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/"   >Inicio</NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/nosotros"  >Nosotros</NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/alturas"  >Trabajo en alturas</NavLink>
                </li>


                <li className="nav-item d-none">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/cursos"  >Otros cursos</NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/contactenos"  >Contactenos</NavLink>
                </li>
                <li className="nav-item d-none">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/legal"  >Legal</NavLink>
                </li>

                <li className="nav-item flare">
                  <a className="nav-link mx-3" href="http://www.consulta.ayeocupacionales.com.co/" target='blank' >
                    Consulta certificado<span className="ms-2">→</span></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>



    </div>

  )

}