import Nosotros from './../img/nosotros.png'


export default function QuienesSomos() {

    return (

        <div>
            <div className="row mt-2">
                <div className="col-12 col-sm-6 boxcenter text-center">
                    
                        <p>
                            <strong style={{fontSize:'30px'}}>Quienes somos</strong><br/><hr></hr>
                            Somos especialistas en riesgos laborales
                            Somos una empresa experta en SEGURIDAD Y SALUD EN EL TRABAJO con énfasis en capacitación y certificación en TRABAJO SEGURO EN ALTURAS y elaboración de ESTUDIOS DE PUESTOS DE TRABAJO para determinación de origen de enfermedad común o laboral.
                            Próximamente con Centro de Entrenamiento autorizado para la formación y certificación en TRABAJO SEGURO EN ESPACIOS CONFINADOS.
                            </p>
                </div>

                <div className="col-12 col-sm-6">
                    <img src={Nosotros} width='100%' ></img>
                </div>

            </div>
        </div>
    )

}