

import CardHorizontalImgSmall from './CardHorizontalImgSmall';

export default function NivelesTrabajoEnAlturas(){


    const items = [
        { id: 1, folder: 'tsa', imagen: 'tsa.png', titulo: 'TRABAJADOR AUTORIZADO', contenido: 'Este nivel está dirigido al personal expuesto sobre los 2 metros de altura de acuerdo a la normatividad legal vigente. Este curso lo toman por primera vez las personas que no se han certificado en alturas' },
        { id: 2, folder: 'tsa', imagen: 'tsa.png', titulo: 'REENTRENAMIENTO', contenido: 'Este nivel está dirigido al personal que con anterioridad haya realizado y aprobado el curso para trabajo en alturas de nivel trabajador autorizado' },
        { id: 3, folder: 'tsa', imagen: 'tsa.png', titulo: 'COORDINADOR DE ALTURAS', contenido: 'Este nivel está dirigido para el personal especialista o profesional que dentro de sus funciones se encuentra: garantizar la seguridad de los trabajadores que se exponen a riesgos de caída.' },
        { id: 4, folder: 'tsa', imagen: 'tsa.png', titulo: 'ACTUALIZACIÓN COORDINADOR', contenido: 'Este nivel está dirigido al personal que con anterioridad haya realizado y aprobado el curso para trabajo en alturas de nivel coordinador.' },
        { id: 4, folder: 'tsa', imagen: 'tsa.png', titulo: 'JEFE DE AREA', contenido: 'Este nivel está dirigido al personal cuyas funciones únicamente son aplicadas en tierra (suministro, aseo u orden de equipos de protección personal) es decir no ejecutan ninguna actividad con riesgo de caída' },
    ];


return(
    <div className="w-100 pt-2 text-center">
    <h2 className='p-3 fs-3 border-top border-3 '>
        Trabajo en alturas
    </h2>
    <p>Capacitación y entrenamiento en protección contra caídas para
 trabajo en alturas en los siguientes niveles de formación: </p>
    <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontalImgSmall key={item.id}  {...item} />)
                    })
                }
            </div>
</div>
)


}